export const FirebaseConfig = {
	"projectId": "uberapp2023-62a80",
	"appId": "1:1009638942692:web:a31f5bd50044c3c9b3c9f1",
	"databaseURL": "https://uberapp2023-62a80-default-rtdb.firebaseio.com",
	"storageBucket": "uberapp2023-62a80.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyC3TciZmvVQpTN0QILHtTV2YSixK1ltm98",
	"authDomain": "uberapp2023-62a80.firebaseapp.com",
	"messagingSenderId": "1009638942692",
	"measurementId": "G-XNZ82VZ76B"
};